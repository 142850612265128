import contact from "./contact";
import socials from "./socials";

const footer = [
  {
    title: "Firma",
    elements: [
      {
        label: "Pfleger Bau Service GmbH",
        type: "text",
      },
      {
        label: "Europaallee 33",
        type: "text",
      },
      {
        label: "67657 Kaiserslautern",
        type: "text",
      },
    ],
  },
  {
    title: "Kontakt",
    elements: contact,
  },
  {
    title: "Soziales",
    elements: socials,
  },
  {
    title: "Rechtliches",
    elements: [
      {
        label: "Impressum",
        type: "location",
        to: "/impressum",
      },
      {
        label: "Datenschutzerklärung",
        type: "location",
        to: "/datenschutz",
      },
    ],
  },
];

export default footer;
